import * as React from "react";
import PageComponent from "src/components/pages/contact";

const Page = ({ location }) => {
  const copy = {
    title:
      "If you have questions or comments, or would like a free phone or video conference consultation, please contact us.",
    fields: {
      name: "Name",
      phone: "Phone",
      email: "Email",
      message: "Message",
    },
    success: (
      <span>
        Thank you for your message.
        <br />
        Someone will be in touch with you shortly.
      </span>
    ),
    error: "An error has occurred. Please try again later.",
    button: "Submit",
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Contact Us"
    />
  );
};

export default Page;
