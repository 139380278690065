import * as React from "react";
import Layout from "src/components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "src/components/ContactForm";

const PageComponent = ({ location, copy, pageTitle, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <article className="lg:flex lg:flex-wrap inner-content mx-auto">
        <h2 className="mx-auto py-10 text-4xl font-normal text-center leading-normal">
          {copy.title}
        </h2>
        <section className="flex flex-col mx-auto sm:w-2/3 lg:w-1/2 text-center">
          <ContactForm copy={copy} />
        </section>
        <section className="mx-auto w-5/6 md:w-2/3 lg:w-1/2 pb-48">
          <div
            className="grid grid-cols-12 gap-y-6 gap-x-2 mt-10 md:ml-10"
            dir="ltr"
          >
            <div className="col-span-3 md:col-span-2 bg-blue-light inline-block w-12 h-12 py-3 text-center rounded-full">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                color="#fff"
                className="h-4 w-4"
              />
            </div>
            <p className="col-span-9 md:col-span-10">
              SPETNER ASSOCIATES
              <br />
              8630 Delmar Blvd
              <br />
              Suite 100 <br />
              St. Louis, MO 63124
            </p>
            <div className="col-span-3 md:col-span-2 bg-blue-light inline-block w-12 h-12 py-3 text-center rounded-full">
              <FontAwesomeIcon
                icon={faPhoneAlt}
                color="#fff"
                className="h-4 w-4"
              />
            </div>
            <p className="col-span-9 md:col-span-10 self-center">
              <a href="tel:+13144420000">+1.314.442.0000</a>
            </p>
            <div className="col-span-3 md:col-span-2 bg-blue-light inline-block w-12 h-12 py-3 text-center rounded-full">
              <FontAwesomeIcon icon={faFax} color="#fff" className="h-4 w-4" />
            </div>
            <p className="col-span-9 md:col-span-10 self-center">
              +1.314.442.0051
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default PageComponent;
